@import '../../node_modules/@fullcalendar/core/main.css';
@import '../../node_modules/@fullcalendar/daygrid/main.css';
@import '../../node_modules/@fullcalendar/timegrid/main.css';
@import 'themes/colors';

.fc-now-indicator {
  border: 0 solid #EC008C;
}
/* Section Header (BookingDateField Title) */
.fc-header-toolbar h2 {
  font-weight: normal;
}

/* Table ChatForm */
.fc-ltr {
  color: white;
}
.fc-body > tr > td.fc-widget-content {
  border-color: transparent;
}

/* All Day Section */
.fc-widget-content > .fc-day-grid, hr.fc-widget-header {
  display: none;
}

/* Calendar Header */
.fc-head-container {
  &, & td, .fc-row, thead {
    border-color: transparent !important;
  }
}
.fc-day-header {
  span {
    color: white;
    font-weight: normal;
    font-size: 1.25rem;
  }
}

/* Header Columns (aka Day Columns) */
.fc-widget-header > table > thead > tr > th {
  border-color: transparent;
}
.fc-bg {
  & > table > tbody > tr > td {
    border-color: $IndyWorkGray_d;
  }
  /* Today Column */
  .fc-today {
    background: transparent !important;
  }
}

/* Header Rows (aka Time Rows) */
td.fc-time {
  border-color: transparent;
}

.fc-time-grid {
  .fc-slats .fc-minor td {
    border: none;
  }

  .fc-event {
    margin-right: 5% !important;
  }
}

// Force calendar to spread to the bottom of the page regardless of the resolution by overriding library inline calculation styles
.fc-time-grid-container {
  height: calc(100vh - 240px) !important;
}

.fc-width {
  &-half {
    width: 50%;
  }

  &-third {
    width: 33.3333%;
  }
}
.fc-slats {
  span {
    color: $IndyWorkGray_d;
  }
  & > table > tbody > tr > td:nth-child(2) {
    border-width: 3px;
    border-color: $IndyWorkNavBar;
    border-left: none;
  }
}

/* Closed Hours */
.fc-nonbusiness {
  background: repeating-linear-gradient(
                  -45deg,
                  $IndyWorkRed_ll,
                  $IndyWorkRed_ll,
                  transparent 4px,
                  transparent 8px
  );
  border: 1px solid $IndyWorkRed_ll;
  border-radius: 4px;
  margin: 3px;
  &:after {
    content: 'CLOSED';
    background-color: $IndyWorkNavBar;
    text-align: center;
    width: 80%;
    height: 25px;
    position: absolute;
    top: 45%;
    left: 10%;
  }
}
.fc-dayGridMonth-view {
  .fc-nonbusiness {
    &:after {
      display: none;
    }
  }

  /* Month day circle */
  .fc-day-top:not(.fc-other-month) > span {
    padding: 1px;
    margin: 5px 10px 0 0;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    text-align: center;
    background-color: $IndyWorkPurpleNew;
  }
}

.fc-dayGrid-view .fc-week-number,
.fc-dayGrid-view .fc-day-number {
  padding: 5px 10px 0 0;
}


/* Shifts */
.fc-event {
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  box-shadow: none;
  cursor: pointer;
  overflow-y: auto;
  &:hover {
    box-shadow: 3px 3px 5px  $IndyWorkPurpleNew;
  }
}
.fc-not-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
}
.fc-mirror {
  border-left: 5px solid $IndyWorkGray_d;
  background-color: $IndyWorkGray_d_50;
}
.fc-shift-event {
  border-left: 5px solid $IndyWorkOrange_l;
  background-color: $IndyWorkOrange_l_50;
}
.fc-shift-promo {
  border-left: 5px solid $IndyWorkYellow_ll;
  background-color: $IndyWorkYellow_ll_50;
}
.fc-shift-audition {
  border-left: 5px solid #ff6dc4;
  background-color: $WorkerPink;
}
.fc-shift-unfilled {
  border-left: 5px solid $IndyWorkRed_l;
  background-color: $IndyWorkRed_l_50;
}
.fc-shift-action-needed {
  border-left: 5px solid $IndyWorkPurpleNew;
  background-color: $IndyWorkPurpleNew_50;
}
.fc-shift-filled {
  border-left: 5px solid $IndyWorkGreen_ll;
  background-color: $IndyWorkGreen_ll_50;
}
.fc-medium-font {
  font-size: 1.15rem;
  font-weight: bold;
  .fc-content {
    margin: 5px 10px;
  }
}
.fc-small-font {
  font-size: 0.85rem;
  .fc-content {
    margin: 3px 5px;
  }
}

/* More events/shifts popover */
.fc-popover {
  border: 1px solid $IndyWorkPurpleNew !important;
  border-radius: 2px;
  background-color: $IndyWorkPurple_dd !important;
  .fc-header {
    background-color: $IndyWorkNavBar !important;
    color: white;
  }
}

// Can't use compose scrollBar here since we need to override inline styling properties
.fc-scroller{
  overflow-y: auto !important;
  overflow-x: hidden !important;

  &::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    background: $ScrollBar !important;
  }

  &::-webkit-scrollbar-thumb {
    background: $IndyWorkWhite_d !important;
  }
}
