@import 'src/sass/themes/colors';

.filterWrapper {
  width: 100%;
  border-bottom: solid 1px $IndyWorkPaneDivider;
  display: flex;
  font-size: 13px;
  font-weight: bold;
  padding: 0.3rem;
  margin-bottom: 20px;
}

.leftColumnWrapper {
  display: flex;
  flex: 1;
  > div {
    display: flex;
    padding-right: 0.5rem;
    height: 100%;
    align-items: center;
    padding-right: 2rem;
    &:not(:last-child){
      border-right: 1px solid $IndyWorkPaneDivider;
    }
    + div:not(:first-child) {
      margin-left:2rem;
    }
  }
}

.control {
  margin-left:0.5rem;

  select {
    width: 150px;
    color: $IndyWorkPurpleNew;
    margin-right: 10px;
    padding: 8px;
    font-weight: normal;

    + svg {
      top: 11px;
      right: 16px;
    }
  }
}

.select {
  padding: 8px !important;
  min-width: 215px;
  + svg {
    top: 12px;
  }
}

.disabled {
  cursor: default;
  color: $DarkGrey
}
