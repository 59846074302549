@import 'src/sass/heading';
@import 'src/sass/scroll-bar';
@import 'src/sass/error-styles';
@import 'src/sass/themes/colors';

.verificationList {
  composes: scrollBar;
  height: calc(100vh - 150px);
}

.verificationLoadingRow {
  color: $IndyWorkWhite_d;
  margin: 80px auto 160px;
  font-size: 24px;
  position: relative;
}
