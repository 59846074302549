@import 'src/sass/themes/fonts';
@import 'src/sass/buttons/button.module';
@import 'src/sass/scroll-bar';
@import 'src/sass/table-styles';

$SECOND_ROW_WIDTH: 220px;
$ACTION_ROW_WIDTH: 100px;

.coursesTableHeader {
  composes: tableHeader;
  div:nth-child(1) {
    flex: 0 1 250px;
    margin-left: 190px;
  }
  div:nth-child(2) { flex: 1 0; }
  div:nth-child(3) { flex: 0 1 110px; }
}

.coursesList {
  composes: scrollBar;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: calc(100% - 180px);  
}

.courseItem {
  display: flex;
  transition: 300ms ease all;
  margin-bottom: 10px;
}

.courseImgArea {
  flex: 0 1 150px;
  margin-right: 40px;
  img {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
  }
}

.courseDetails {
  flex: 0 1 $SECOND_ROW_WIDTH;
  margin-right: 30px;
  h4 {
    &::first-letter {
      text-transform: capitalize;
    }
    margin: 0 0 10px;
    font-size: 16px;
    font-family: $Roboto;
  }

  h5 {
    margin: 15px 0 0;
  }

  div {
    display: flex;
    color: $IndyWorkGray_d;
    span {
      margin-left: 5px;
      color: $white;
    }
  }

  p {
    margin: 5px 0 0;
    color: $IndyWorkPurpleNew;
  }
}

.courseDescription {
  flex: 1 0;
  margin: 0;
}


.courseActionArea {
  flex: 0 1 $ACTION_ROW_WIDTH;
  margin-right: 20px;

  a {
    width: 100%;
    text-decoration: none;
    display: block;
  }

  button {
    width: 100%;
    margin-bottom: 10px;
  }
}
