@import 'src/sass/themes/colors';
@import 'src/sass/heading';
@import 'src/sass/layout-styles.module';

.dancerSearchHeading {
  composes: h1Heading;
  width: 100%;
  display: flex;
  border-bottom: solid 1px $IndyWorkPaneDivider;
}


